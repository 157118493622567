import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
    BlockTitle, MainText
} from '../../components/Text';
import { MainContainer } from '../../components/Container';

const BlockImageDesktop = styled.img`
  max-width: 40%;

  ${({ theme }) => theme.media.tablet`
      display: none;
  `}
`;

const BlockImageMobile = styled.img`
  display: none;

  ${({ theme }) => theme.media.tablet`
    display: block;
    max-width: 80%;
    margin: 0 auto;
    max-height: 300px;
  `}
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 60px;

  ${({ theme }) => theme.media.tablet`
    flex-direction: column;
  `}
`;

const Block = styled.div`
  ${({ marginLeft }) => marginLeft ? 'margin-left: 20px;' : ''}
  ${({ marginRight }) => marginRight ? 'margin-right: 20px;' : ''}

  ${({ theme }) => theme.media.tablet`
    margin-left: 0;
    margin-right: 0;
  `}
`;

const Resume = () => {
    const { t } = useTranslation();

    return (
        <MainContainer>
            <BlockTitle>
                {t('history')}
            </BlockTitle>
            <Row>
                <Block marginRight>
                    <MainText>
                        {t('company_history_text')}
                    </MainText>
                    <BlockImageMobile
                        src="/images/company_1.jpg"
                        alt="Entreprise résistances stéatites"
                    />
                    <BlockTitle>
                        {t('company_know_how_title')}
                    </BlockTitle>
                    <MainText>
                        {t('home_expert_text')}
                    </MainText>
                    <MainText>
                        {t('home_expert_text_2')}
                    </MainText>
                </Block>
                <BlockImageDesktop
                    src="/images/company_1.jpg"
                    alt="Entreprise résistances stéatites"
                />
            </Row>
        </MainContainer>
    );
};

export default React.memo(Resume);
