import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { PageSubTitle } from '../../components/Text';
import StyledLocalizedLink from './../StyledLocalizedLink';

const Container = styled.div`
    background-color: rgba(${({ theme }) => theme.colors.mainBlue.rgb}, 0.15);
    padding: 60px 0px;
`;

const ContentContainer = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    ${({ theme }) => theme.media.littleScreen`
      width: calc(100% - 10px);
      overflow: hidden;
    `}
`;

const LinkButton = styled(StyledLocalizedLink)`
    margin-top: 25px;
`;

const PageSubTitleStyled = styled(PageSubTitle)`
    text-transform: uppercase;
    position: relative;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        width: 60%;
        z-index: 0;
        background-color:${({ theme }) => theme.colors.mainBlue.hex};
    }
    &:after {
        right: -60%;
    }
    &:before {
        left: -60%;
    }
  
    ${({ theme }) => theme.media.mobile`
        text-align: center;
    `}  
`;

const Information = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <ContentContainer>
                <PageSubTitleStyled>
                    {t('need_information')}
                </PageSubTitleStyled>
                <LinkButton to="contact">
                    {t('contact_us')}
                </LinkButton>
            </ContentContainer>
        </Container>
    );
};

export default React.memo(Information);
