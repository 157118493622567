import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Layout from '../../components/Layout';
import { PageTitle } from '../../components/Text';

import Resume from '../../components/company/Resume';
import Information from '../../components/company/Information';

const Container = styled.div`

`;

const Company = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <Helmet>
                <title>
                    {t('company_meta_title')}
                </title>
                <meta name="description"
                    content={t('company_meta_description')} />
            </Helmet>
            <Container>
                <PageTitle>
                    {t('company')}
                </PageTitle>
                <Resume />
                <Information />
            </Container>
        </Layout>
    );
};

export default React.memo(Company);
